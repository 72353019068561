import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageTransactionService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }



    createDisease(data: any) {
        return this.http.post(this.url + 'disease/create', data);
    }


    getConvenienceFee(data: any) {
        return this.http.get(this.url + 'admin/get-convenience-fee', data);
    }

    deleteDisease(data: any) {
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body: data
        // };
        return this.http.post(this.url + 'disease/delete', data);
    }

    addEditPercentage(data: any) {
        return this.http.post(this.url + 'admin/add-convenience-fee', data);
    }

}
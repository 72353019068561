import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageMembershipService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    listCancelRequest(data: any) {
        return this.http.post(this.url + 'admin/list-cancel-request', data);
    }

    changeCancelRequestStatus(data: any) {
        return this.http.post(this.url + 'admin/change-cancel-request-status', data);
    }



}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() { }
  manageContractorHeading = new BehaviorSubject(false);
  manageFeedsHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading = new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageBusinessHeading = new BehaviorSubject(false);
  manageCategoriesHeading = new BehaviorSubject(false);
  manageTransactionHeading = new BehaviorSubject(false);
  manageCouponHeading = new BehaviorSubject(false);
  manageMembershipHeading = new BehaviorSubject(false);

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm: FormGroup;
  hide = true;
  hide1 = true;
  hide2 = true;
  userType: any = 1;
  check: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }



  forgetEmail: any;

  ngOnInit() {
    this.initForm();
    this.forgetEmail = JSON.parse(localStorage.getItem('email'));
    this.loginForm.get('email').patchValue(this.forgetEmail);
    let currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.router.navigateByUrl('/edit-profile');
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  initForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.emailreg)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
    };
    this.authService.login(user).subscribe(
      (res: any) => {
        if (res.status == 200) {
          localStorage.setItem('currentUser', JSON.stringify(res.output));
          // this.router.navigateByUrl('/analytics-dashboard');
          this.router.navigateByUrl('/manage-feeds');
          localStorage.removeItem('receiveEmail');
        }
        else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        this.toastr.error(err?.error?.error?.message);
      }
    );
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageFeedsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data: any) {
        return this.http.post(this.url + 'user/upload-image', data);
    }

    categoryList(data: any) {
        return this.http.post(this.url + `category/list`, data);
    }

    createFeeds(data: any) {
        return this.http.post(this.url + 'newsfeed/create', data);
    }

    updateFeeds(data: any) {
        return this.http.put(this.url + 'newsfeed/edit', data);
    }

    feedsList(data: any) {
        return this.http.post(this.url + 'newsfeed/list', data);
    }


    getFeedById(data: any) {
        return this.http.post(this.url + "newsfeed/view", data);
    }

    deleteNewsFeed(id: any) {
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body: id
        // };
        return this.http.delete(this.url + 'newsfeed/delete/' + id.id);
    }

    businessList(data) {
        return this.http.post(this.url + `admin/get-business-profile`, data);
    }
}